import ProjectsData from './ProjectsData';
import PageHeader from "../../components/PageHeader";


const ProjectsList = props => {

  return (
    <div>
       <PageHeader title="PROJECTS" description="A compilation of pieces that skillfully brings together the creations of our clients and the technology employed to capture them." />
        <div className="container-full-width">
              <div className="projects-items-wrapper">
                  <ProjectsData />

              </div>
        
                       
        </div>
    </div>
  );
};

export default ProjectsList;
