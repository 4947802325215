import React, { useState, useEffect } from 'react';
import contentfulConfig from '../../config/contentfulConfig';
import { createClient } from 'contentful';
import { Link } from 'react-router-dom';

const client = createClient({
  space: contentfulConfig.space,
  accessToken: contentfulConfig.accessToken,
});

function ProjectsData({ max_items }) {
  const [entries, setEntries] = useState([]);

  useEffect(() => {
    // Fetch data from Contentful
    client
      .getEntries({
        content_type: 'projects',
        // You can add additional filters or options here
      })
      .then((response) => {
        setEntries(response.items);
      })
      .catch(        
        console.error
      );
  }, []);
  const limitedEntries = max_items ? entries.slice(0, max_items) : entries;

  return (
    <>
        {limitedEntries.map((entry) => (
        <Link className='item' to={'/projects/' + entry.sys.id} key={entry.sys.id}>
          <img className='featured-image' src={entry.fields.featuredImage.fields.file.url} alt={entry.fields.title} />
          <div className='description-box'>
            <div className='title'>{entry.fields.title}</div>
            <div className='type'>WEB</div>
          </div>
        </Link>
      ))}
    </>
  );
}

export default ProjectsData;
