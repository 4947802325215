import React, { useState, useEffect } from 'react';
import contentfulConfig from '../../config/contentfulConfig';
import { createClient } from 'contentful';
import { Link } from 'react-router-dom';
import Loading from "../../components/Loading";

const client = createClient({
  space: contentfulConfig.space,
  accessToken: contentfulConfig.accessToken,
});

function ServicesData() {
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Fetch data from Contentful
    client
      .getEntries({
        content_type: 'services',
        // You can add additional filters or options here
      })
      .then((response) => {
        setEntries(response.items);
        setIsLoading(false); // Set loading state to false when data is fetched
      })
      .catch(console.error);
  }, []);

  return (
    <>
    
      {isLoading ? ( // Check loading state
        <Loading />
      ) : (
        entries.map((entry) => (
          <Link className='item' to={'/services/' + entry.sys.id}>
            <img className='featured-image' src={entry.fields.icon.fields.file.url} alt={entry.fields.title} />
            <div className='description-box'>
              <div className='title' key={entry.sys.id}>{entry.fields.title}</div>
            </div>
          </Link>
        ))
      )}
    </>
  );
}

export default ServicesData;
