import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      

      <section className='text-dark'>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                TOP TECH GIANTS
              </h6>
              <p>
              Top Tech Giants is the team of most skilled coders. We create incredible digital experiences.
              </p>
            </MDBCol>

            

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              
              <h6 className='text-uppercase fw-bold mb-4'>CONTACT</h6>
              
              <p>
                <a className='text-dark text-decoration-none' href='mailto:info@toptechgiants.com'>
                <MDBIcon icon="envelope" className="me-3" />
                info@toptechgiants.com
                </a>
              
              </p>
             {/* <p>
              <a target='_blank' className='text-dark text-decoration-none' href='https://www.linkedin.com/in/toptechgiants/' >
                <MDBIcon fab icon="linkedin" className='me-3' />
                linkedin.com/in/toptechgiants
              </a>
             </p> */}
             {/* <p>
              <a className='text-dark text-decoration-none' href='https://github.com/sudarshangiri' >
                <MDBIcon fab icon="linkedin" className='me-3' />
               github.com/sudarshangiri
              </a>
             </p> */}
              <p className='text-dark text-decoration-none'>
                <MDBIcon icon="home" className="me-3" />
                Lalitpur, Nepal
              </p>
            
             
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4 text-dark' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', borderTop: '1px solid #000',  borderBottom: '1px solid #000' }}>
        © 2023 Copyright:
          toptechgiants.com
        
      </div>
    </MDBFooter>
  );
}