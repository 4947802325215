import React from 'react';
import Typewriter from 'typewriter-effect';
import Myphoto from './images/myphoto.png'; // Adjust the path to your image file
import ParticlesBackground from './ParticlesBackground';
import zIndex from '@mui/material/styles/zIndex';

const  Intro=()=>{
    return(
        <div className="section intro">
            
            
            <div className="container-full-width">
            
                <small className='text-white'>FULL SERVICE SOFTWARE DIGITAL AGENCY</small>
                <h1 className='title'>WE DON'T JUST <span style={{display:"inline-flex"}}>{`<`}CODE {`/>`}</span> BUT LIVE FOR IT.</h1>
                <div className=' subtitle col-md-5  text-white '>We help businesses, start-ups and nonprofits scale
and create brands, websites, tools, and apps that customers love.</div>
          
                
                

            
               
                <ParticlesBackground/>

                    
            </div>
            <div className='background-image'></div>

        </div>
        
    )



}

export default Intro;