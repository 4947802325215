import React from "react";
import PageHeader from "../../components/PageHeader";
import ServicesData from "./ServicesData";


const Services = () =>{
    
    return(
        <>
            <PageHeader 
            title="Services" 
            description="Web Design & Development, SEO, Branding, DevOps, Marketing, Bespoke Applications and more..." 
            />
            <div className="container-full-width">
                <div className="services-items-wrapper" >
                    <ServicesData />

                </div>
            </div>
            

        </>

    );
}

export default Services;