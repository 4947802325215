import React from "react";
import Intro from './Intro';
import Marquee from "react-fast-marquee";
import WorkExperienceTimeline from "../../components/WorkExperienceTimeline";
import ParticlesBackground from './ParticlesBackground';
// import ParticlesBackground2 from './ParticlesBackground2';
import { Parallax, Background } from "react-parallax";
import { render } from "react-dom";
import   image1 from "./images/keyboard.jpg";
import ProjectsData from '../Projects/ProjectsData';
import HomeHeroSlider from './HomeHeroSlider/HomeHeroSlider';
import TestimonialSlider from './TestimonialSlider';
import Clients from "./Clients";

const techStackTerms = [
    "JavaScript",
    "React",
    "Node.js",
    "Python",
    "Angular",
    "Vue.js",
    "HTML5",
    "CSS3",
    "Sass",
    "MongoDB",
    "SQL",
    "Express.js",
    "Django",
    "Ruby",
    "C#",
  
  ];
  const techStackTerms2 = [
    "REST API",
    "GraphQL",
    "Webpack",
    "Redux",
    "Vue.js",
    "TypeScript",
    "SASS/SCSS",
    "Firebase",
    "TensorFlow",
    "PyTorch",
    "D3.js",
    "Bootstrap",
    "Material-UI",
    "Webpack",
    "Elasticsearch",
    
  ]
  const techStackTerms3 = [
    "ASP.NET",
    "Spring Boot",
    "PHP",
    "Laravel",
    "AWS",
    "Docker",
    "Kubernetes",
    "Git",
    "Jenkins",
    "CI/CD",
    "Kibana",
    "Redis",
    "PostgreSQL",
    "MySQL",
  
  ]
  

  const technologY_stacks_images_row_1 = [

  ]
const Home = () =>{
   
    
    return(
        <>

        <Intro />

            <section id="intro-wrapper" className="">
                {/* <ParticlesBackground2 /> */}
            </section>
            <div className="section section-recent-projects border-top">
                <div className="container-full-width">
                    <h2 className="section-title">RECENT PROJECTS.</h2>
                    <div className="projects-items-wrapper">
                        <ProjectsData max_items="6"/>

                    </div>

                </div>

            </div>
            <div className="section section-marquee-text border-top-bottom">
                <div className="container-full-width">
                    <h2 className="section-title">TECH STACKS.</h2>

                </div>
                <div className="border-top-bottom py-5 mx-5">
                    <Marquee direction="right" speed="50">
                        {techStackTerms.map((term, index) => (
                            <div className="text-item">{techStackTerms.join(" / ")}</div>
                        ))}
                    </Marquee>
                    <Marquee direction="left"  speed="60">
                        {techStackTerms.map((term, index) => (
                            <div className="text-item">{techStackTerms2.join(" / ")}</div>
                        ))}
                    </Marquee>
                    <Marquee direction="right"  speed="50">
                        {techStackTerms.map((term, index) => (
                            <div className="text-item">{techStackTerms3.join(" / ")}</div>
                        ))}
                    </Marquee>
                </div>
            </div>
            <Parallax bgImage={image1} strength={500}>
                <div className="parallax-home" style={{ height: 500 }}>
                    <div className="inner-text">
                        <div className="container-full-width col-md-8 display-4">
                        A MASTER HAS FAILED MORE

<br />THAN THE BEGINEER HAS EVER TRIED.
                        </div>
                    </div>
                
                </div>
            </Parallax>


            <div id="section-clients" className="section section-clients border-bottom border-top">
                <div className="container-full-width">
                    <h2 className="section-title">our
evolutionary client network.</h2>
                    <Clients />

                </div>

            </div>
            <div className="section section-testimonials">
                <div className="container-full-width">
                    <h2 className="section-title">WHAT ARE CLIENTS SAY.</h2>
                    <TestimonialSlider />

                </div>

            </div>
           
        </>

    );
}

export default Home;